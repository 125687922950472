
@import url('font-awesome/css/all.min.css');
@import url('https://fonts.googleapis.com/css2?family=Kanit&display=swap');

$bg-color: #65ccb8;
$mid-bg-color: #e5e5e5;
$light-bg-color: white;
$menu-height: 60px;
$purple-gradient: linear-gradient(60deg,#ab47bc,#7b1fa2);
$box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%);

.App {
  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 18px;
}

body {
	background-color: $bg-color;
}

html {
	scroll-behavior: smooth;
}

.anchor-offset {
	padding-top: $menu-height + 20px;
    margin-top: -($menu-height + 20px);
    height: 0px;
}

.content-outer {
	position: absolute;
	width: 100%;
	z-index: 98;
}


.content-inner {
	margin: auto;
	padding: 40px;
	max-width: 1120px;
	position: relative;
}

/***************** Main menu ******************/


.navbar {
    margin: auto;
	padding: 20px;
	padding-top: 40px;
	width: 100%;
	height: $menu-height;
	position: relative;
	opacity: 1;
	transition: 0.3s;
}

.navbar-scrolled {
    margin: auto;
	padding: 20px;
	width: 100%;
	height: $menu-height;
	position: fixed;
	opacity: 1;
	background: #e7e7e7;
	z-index: 99;
	transition: 0.3s;
}

.navbar-inner {
	margin: auto;
	max-width: 1120px;
	position: relative;
	transform: translateY(-50%);
	top: 50%;
}
.navbar-buttons {
	float: right;
	position: relative;
	transform: translateY(-50%);
	top: 50%;
	li {
		float: left;
	  	list-style: none;
	  	text-align: center;

		border: none;
		text-align: center;
		font-size: 12px;
		
		transition: 0.3s;
		text-transform: uppercase;
		a:link {
			border-radius: 8px;
			padding: 16px 16px;
	      	text-decoration: none;
	   		color: white;
	   		opacity: 0.6;
		}
		a:visited {
		   	text-decoration: none;
	   		color: white;
		}
	}
	li:hover {
		a:link {
 			text-decoration: none;
  			background-color: #e7e7e7;
  			text-decoration-color: black;
	  		opacity: 1;
			padding: 16px 16px;
      		text-decoration: none;
	   		color: black;
		}
		a:visited {
	   		text-decoration: none;
	   		color: black;
		}
	}
}


.header-block-outer {
	height: 400px;
	text-align: right;
	line-height: 1.4;
	.header-block-inner {
		margin: auto;
		text-align: right;
		line-height: 1.4;
	    position: relative;
	    top: 50%;
	    transform: translateY(-50%);
	    h1 {
			font-size: 67px;
		}
		p {
	    	font-size: 18px;
		}
	}
}

.main-block {
	.narrow {
		padding-left: 160px;
		padding-right: 160px;
	}
	.wide {
		padding-left: 0px;
		padding-right: 0px;
	}
	line-height: 1.5;
	background: $light-bg-color;
	text-align: center;
	margin-bottom: 20px;
	h1 {
		font-size: 30px;
	}
	h2 {
		margin-top: 25px;
		font-size: 20px;
	}
	p {
		margin-top: 25px;
	}
	.values-grid {
		h1 {
			font-size: 20px;
		}
		p {
			color: #999;
		}
		font-size: 14px;
		margin-top: 80px;
		margin-bottom: 40px;
		display: grid;
		grid-gap: 20px;
		grid-template-columns: repeat(3, 1fr);
		.values-item {
			.icon {
				margin-bottom: 25px;
				content: "\f1d7";
				transition: .3s ease;
			    font-size: 61.6px;
			    font-family: "Font Awesome 5 Brands";
			    -webkit-font-smoothing: antialiased;
    			display: inline-block;
    			font-style: normal;
    			font-variant: normal;
    			text-rendering: auto;
			    line-height: 1;
			}
		}
	}
	a {
		margin: auto;
  		border: 3px solid green;
  		padding: 10px;
		text-decoration: none;
		font-size: 18px;
		color: #000;
		margin-top: 5px;
		text-align: center;
		background: $bg-color;
		border-radius: 6px;
		display: block;
  		margin-left: auto;
  		margin-right: auto;
    	box-shadow: 0 16px 38px -12px rgba(0,0,0,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(0,0,0,.2);
	}
}

.about-me {
	background-color: $mid-bg-color;
	h1 {
		font-size: 30px;
	}
	.about-me-inner {
		margin: auto;
		max-width: 600px;
		padding: 40px;
	}
	.about-me-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.about-me-grid-narrow {
		display: block;
	}
	.about-me-left {
		img {
			transition: 1s ease-in;
			box-shadow: 5px 10px 5px lightgrey;
			-webkit-box-shadow: $box-shadow;
			text-align: center;	
			width: 200px;
			height: 200px;
			margin: 20px;
		    border-radius: 12px;
		}
	}
	.about-me-right {
		text-align: left;
		h2 {
			margin-top: 25px;
			font-size: 20px;
		}
		h3 {
			color: #3c4858;
			margin-top: 20px;
			font-size: 14px;
		}
		p {
			color: #3c4858;
			margin-top: 20px;
			font-size: 14px;
		}		
	}
}

.testimonials {
	background-color: $light-bg-color;
	padding-top: 30px;
	padding-bottom: 30px;
	.testimonials-inner {
		margin: auto;
		margin-top: 50px;
		margin-bottom: 50px;
	}
	.testimonial {
		max-width: 700px;
		margin: auto;
		height: 250px;
		.testimonial-inner {
			position: relative;
			top: 50%;
	    	transform: translateY(-50%);
			box-shadow: 5px 10px 5px lightgrey;
			-webkit-box-shadow: $box-shadow;
			margin: auto;
			padding: 12px;
			border-radius: 12px;
			background: #eeeeee;
			p {
				font-size: 18px;
				font-style: italic;
				padding: 8px;	
			}
		}
	}
}

footer {
	border-top: solid 1px;
	background: #e7e7e7;
	width: 100%;
	height: 40px;
	margin-top: 40px;
	padding-top: 10px;
	bottom: 0;
	text-align: center;
}


.contact {
	background-color: $mid-bg-color;
	h1 {
		font-size: 30px;
	}
	.contact-inner {
		margin: auto;
		max-width: 1120px;
		padding: 40px;
		p {
			margin-top: 40px;
		}
	}
	.contact-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
	}
	.contact-grid-narrow {
		display: block;
	}
	.contact-left {
		padding: 80px;
		line-height: 1.5;
		text-align: left;
		h1 {
			margin-top: 25px;
			font-size: 30px;
		}
		p {
			color: #3c4858;
			margin-top: 20px;
		}
		.formStatus {
			margin-top: 80px;
			background-color: red;
			color: white;
			text-align: center;
		}
	}
	.contact-right {
		margin:auto;
		font-size: 14px;
		text-align: left;
		color: #999;
		h1 {
			font-size: 20px;
			text-align: center;
			margin: 15px;
			border-radius: 3px;
			background: $purple-gradient;
			color: #fff;
			margin-top: -80px;
			padding: 20px 10px;
			box-shadow:  $box-shadow;
		}
		.contact-card {
			margin: auto;
			margin-top: 40px;
			padding: 20px;
			padding-top: 70px;
			background: white;
			border-radius: 6px;
    		box-shadow:  $box-shadow;
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			width: 400px;
			p, input, textarea, label {
				margin: 10px;
			}
			textarea {
				margin-top: 0px;
			}
			label {
				margin-bottom: 0px;
			}
			input, textarea {
				padding: 8px;
    			border: none;
    			border-bottom: 1px solid #999;
    			resize: none;
    			transition: 0.3s;
			}
			textarea {
				font-family: 'Kanit', sans-serif;
			}
			input:focus, textarea:focus {
				border: none;
				outline: none;
    			border-bottom: 1px solid #ab47bc;
    			transition: 0.3s;
    			box-shadow:  $box-shadow;
    		}

			button {
				text-align: center;
				border-radius: 3px;
				border: none;
				background: $purple-gradient;
				color: #fff;
				padding: 8px;
			}
			.formButton {
				margin-top: 20px;
				height: 40px;
			}
			.formButton-disabled {
				margin-top: 20px;
				height: 40px;
				button {
					background: grey;
					opacity: 0%;
				}
			}
			.form-field-error {
				padding: 5px;
	    		border: 3px solid red;
			}
			.form-field-error:autofill {
				padding: 5px;
    			border: 3px solid red;
			}
			.form-cb-error {
	    		margin: -3px;
	    		border: 3px solid red;
			}


		}
	}
}

.formStatus {
	margin-top: 80px;
	background-color: red;
	color: white;
	text-align: center;
}

.form-page {
	margin-top: 50px;
	padding-top: 50px;
	padding-bottom: 50px;
	vertical-align: center;
}

.button-highlighted
{
	text-decoration: none;
	font-size: 14px;
	align-content: right;
	color: #fff;
	padding: 8px;
	margin-left: 20px;
	background: $purple-gradient;
	border-radius: 6px;
    box-shadow: 0 16px 38px -12px rgba(0,0,0,.56),0 4px 25px 0 rgba(0,0,0,.12),0 8px 10px -5px rgba(0,0,0,.2);
}

